<template>
  <y_layout>
    <YTabs v-model="active_tab">
      <YTabPane label="用户列表" name="list">
        <el-card>
          <success></success>
        </el-card>
      </YTabPane>
      <YTabPane label="黑名单" name="wait">
        <el-card v-if="active_tab === 'wait'">
          <wait></wait>
        </el-card>
      </YTabPane>
    </YTabs>
<!--    <el-tabs v-model="active_tab" type="border-card">-->
<!--      <el-tab-pane label="用户列表" name="">-->
<!--        <success></success>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="黑名单" name="wait">-->
<!--        <wait v-if="active_tab === 'wait'"></wait>-->
<!--      </el-tab-pane>-->
<!--            <el-tab-pane label="虚拟用户" name="fake">-->
<!--              <fake v-if="active_tab === 'fake'"></fake>-->
<!--            </el-tab-pane>-->
<!--    </el-tabs>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import success from "@/view/user/list/success";
import wait from "@/view/user/list/wait";
import YTabs from "@/components/YTabs/YTabs";
import YTabPane from "@/components/YTabs/YTabPane";
// import fake from "@/view/user/list/fake";

export default {
  name: "index",
  components: {
    YTabPane,
    YTabs,
    y_layout,
    success,
    wait,
    // fake,
  },
  data() {
    return {
      active_tab:"list"
    }
  },
  mounted() {
    // let tab = this.$route.query.tab;
    // this.active_tab = tab || "";
  },
  methods: {

  }
}
</script>

<style scoped>

</style>