<template>
  <div>
    <el-collapse accordion>
      <el-collapse-item>
        <template #title>
          <i style="margin-left: 1rem;font-size: 1rem;font-weight: 600;margin-right: .5rem" class="el-icon-search"></i>筛选条件
        </template>
        <div class="flex-def flex-zCenter flex-cCenter">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="用户昵称">
              <el-input v-model="searchForm.nickname" placeholder="用户昵称"></el-input>
            </el-form-item>
            <el-form-item label="注册时间">
              <el-date-picker
                  v-model="searchForm.between"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button @click="searchGo" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="reSetSearch" size="medium" native-type="reset">重置</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="50"></el-table-column>
      <el-table-column label="头像" width="50">
        <template #default="scope">
          <el-avatar shape="square" size="small" :src="scope.row.avatar | tomedia"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="昵称"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="150"></el-table-column>
      <el-table-column label="微信" width="320" show-overflow-tooltip>
        <template #default="scope">
          <div>{{scope.row.m_wxapp_fans ? '小程序:'+ scope.row.m_wxapp_fans.openid + '【'+ scope.row.m_wxapp_fans.unionid +'】' : ''}}</div>
          <div>{{scope.row.m_official_fans ? '公众号:' + scope.row.m_official_fans.openid + '【'+ scope.row.m_official_fans.unionid +'】' : ''}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="注册时间" width="160">
        <template #default="scope">
          {{scope.row.created_at | date}}
        </template>
      </el-table-column>
      <el-table-column prop="black" label="黑名单" width="70">
        <template #default="scope">
          {{scope.row.black ? '是':'否'}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center">
        <template #default="s">
          <div>
<!--            <el-popconfirm @confirm="del(s.$index)" title="确定删除吗？">-->
<!--              <el-button slot="reference" type="danger" size="small">移除</el-button>-->
<!--            </el-popconfirm>-->
            <el-button style="margin-left: .5rem" v-if="!s.row.black" @click="beBlack(s.$index)" size="small">拉黑</el-button>
          </div>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "success",
  data(){
    return{
      searchForm: {
        nickname: "",
        between:[]
      },
      page:1,
      total:0,
      size:15,
      list:[],
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    beBlack(key){
      let id = this.list[key].id
      this.$api.member.black({id}).then(()=>{
        this.$message.success("操作成功");
        this.load()
      })
    },
    del(key){
      let id = this.list[key].id
      this.$api.member.del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load()
      })
    },
    reSetSearch(){
      this.searchForm = {
        nickname: "",
        between:[]
      }
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    searchGo(){
      this.load();
    },
    load(){
      this.loading = true;
      this.$api.member.search({
        page:this.page,
        nickname:this.searchForm.nickname,
        between: this.searchForm.between,
        must_avatar:true,
      }).then(res=>{
        this.loading = false;
        this.total = res.total;
        this.list = res.list;
      })
    }
  }
}
</script>

<style scoped>

</style>